import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Col, Form, Row } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./buyItNow.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { BuyItNow } from "model/product/buyItNow.model";
import ProForm, { ProFormList, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
export interface BuyItNowEditProps extends EditStateAndDispatchProps<BuyItNow>, StateProps, DispatchProps, RouteComponentProps {
}

const BuyItNowEdit: FC<BuyItNowEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<BuyItNow>
      title="一口价商品"
      form={form}
      width={1000}
      {...props}
    >
      <Row>
        <Col span='5'>
          <ProFormText name='retailPrice' label="售价" />
        </Col><Col span='1' />
        <Col span='5'>
          <ProFormText name='originPrice' label="划线价" />
        </Col><Col span='1' />
        <Col span='5'>
          <ProFormText name='minOrderQuantity' label="起订量" />
        </Col><Col span='1' />
        <Col span='2'>
          <ProFormSwitch
            initialValue={false}
            name='isShow' label="是否显示"
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否',
            }}
          />
        </Col><Col span='1' />
        <Col span='3'>
          <ProFormText name='sort' label="排序" />
        </Col><Col span='1' />
      </Row>
      <ProFormList
        label="定价规则"
        name={'rules'} // 关键点：动态命名
        creatorButtonProps={{
          creatorButtonText: '添加',
        }}
        initialValue={[{}]}
        actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
      >
        {(meta, itemIndex, action) => <ProForm.Group size={20}>
          <ProFormText
            width="xs"
            name="quantity"
            label="数量"
          />
          <ProFormText
            width="xs"
            label="单价"
            name="fixedPrice"
          />
        </ProForm.Group>}
      </ProFormList>
    </EditPage>);
};

const mapStateToProps = ({ buyItNow }: RootState) => ({
  entity: buyItNow.entity,
  updateSuccess: buyItNow.updateSuccess,
  updating: buyItNow.updating,
  createSuccess: buyItNow.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BuyItNowEdit);
