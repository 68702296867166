import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { BuyItNow, defaultBuyItNow } from 'model/product/buyItNow.model';
import axios from 'axios';

const entity = 'BuyItNow';
const apiUrl = '/api/yumei-mall-pim/fixed-price-product';
const standardReducerImpl = new StandardReducerImpl<BuyItNow>(entity, defaultBuyItNow, apiUrl);
export type BuyItNowState = StandardState<BuyItNow>;
export const buyItNow = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const addFixedPriceProduct = async (data: any) => {
    const requestUrl = `/api/yumei-mall-pim/fixed-price-product/batch`;
    return await axios.post(requestUrl, data)
}

