/**
 * Ant Design Pro v4 use `@ant-design/pro-layout` to handle Layout.
 * You can view component api by:
 * https://github.com/ant-design/ant-design-pro-layout
 */
import ProLayout, { RouteContext } from '@ant-design/pro-layout';
import React, { useContext, useEffect, useState } from 'react';
import defaultSettings from './default-settings';
import { RouteComponentProps, Link } from '@reach/router';
import RightContent from './right-content';
import { BasicLayoutProps as ProLayoutProps } from '@ant-design/pro-layout/es/BasicLayout';
import { MenuDataItem } from '@ant-design/pro-layout/es/typings';
import menu from 'layouts/menu';
import { HeaderViewProps } from '@ant-design/pro-layout/es/Header';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons/lib';
import { Breadcrumb, Button, Space } from 'antd';
import { AuthContext } from '../common/auth/authorized-route';
import './basic-layout.less';
import { Storage } from 'utils/storage-util';
import SwitchTenant from './switchTenant/swichtenant'
import { getTenantList } from './switchTenant/swichtenant.reducer';
import switchImg from '../assets/switch.png';
import { MenusItem, queryParamGeneral } from 'common/auth/authentication';

export interface BasicLayoutProps
  extends ProLayoutProps,
  Omit<RouteComponentProps, 'location'> {
}

// saber:saber_secret 平台 wholesale:wholesale  团长   seller:seller 商家
const BasicLayout: React.FC<BasicLayoutProps> = (props) => {
  const [switchTenantOpen, setSwitchTenantOpen] = useState(false);
  let [keyIndex, setKeyIndex] = useState<any>(0);
  // 获取租户
  const getTent = () => {
    getTenantList().then((e: any) => {
      let data = e.map((item: any) => ({
        value: item?.id,
        label: item?.name,
        tenantPlatform: item.tenantPlatform
      }))

      if (data.length === 1) {
        Storage.session.set("x-tenant", data[0].value);
        Storage.session.set("x-tenant-data", data[0] || []);
        const tenantRequest = (Storage.session.get("x-tenant-data")) || [];
        defaultSettings.title = tenantRequest.label || '商城';
        queryParamGeneral().then(e => {
          Storage.session.set("param-general", e)
        });
        setKeyIndex(++keyIndex)
      } else if (data.length > 1) {
        setSwitchTenantOpen(true)
      }
    });
  }
  useEffect(() => {
    const accountClient = Storage.session.get("x-tenant");
    if (!accountClient) {
      getTent()
    } else {
      const tenantRequest = (Storage.session.get("x-tenant-data")) || [];
      defaultSettings.title = tenantRequest.label || '商城';
      setKeyIndex(++keyIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tenantRequest = (Storage.session.get("x-tenant-data")) || [];
  // defaultSettings.title = tenantRequest.label || '商城';
  // TODO: 当前租户名称应该和认证信息一样，放在state里面
  const authInfo = useContext(AuthContext);
  const info: any = Storage.session.get('info');
  console.log(info)

  const fetchMenu = (menuList: MenuDataItem[], menus: MenusItem[]): MenuDataItem[] => {
    const oneList = menus.concat().map(e => e.parentMenuCode);
    const newMenuList = info?.admin ? menuList : menuList.filter((item: any) => oneList.indexOf(item.path.split('/')[1]) > -1).map((item: any) => {
      const twoList = menus.concat().filter((e: any) => e.parentMenuCode === item.path.split('/')[1]).map((e: any) => {
        const arr = e.children.map((a: any) => a.menuCode);
        return arr
      });
      console.log(twoList)
      return {
        ...item,
        children: item.children.concat().filter((e: any) => twoList[0].indexOf(e.path) > -1)
      }
    });

    return tenantRequest.value === 'merrige' ? newMenuList.map(e => {
      return {
        ...e,
        children: e.path === '/procure' ? (e.children.filter((s: any) => s.path !== 'refund')) : e.children,
      }
    }) : newMenuList;
  };

  const headerContentRender = (props: HeaderViewProps) => (
    <RouteContext.Consumer>
      {({ collapsed, breadcrumb, isMobile }) =>
        <Space>
          {!isMobile &&
            <Button
              type="text"
              size="large"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => props.onCollapse && props.onCollapse(!collapsed)}
            />}
          <Breadcrumb {...breadcrumb} />
        </Space>}
    </RouteContext.Consumer>);
  return (
    <>
      <ProLayout
        menuHeaderRender={(logo, title) => (
          <div className="menu-header" onClick={() => setSwitchTenantOpen(true)} >
            {/* <div className="img">{logo}</div> */}
            <div className="title">{title}</div>

            {/* 切换租户 */}
            <div className='switch-tenant'><img title='切换租户' style={{ width: '26px', height: 'auto' }} src={switchImg} alt="" /> </div>
            <SwitchTenant visible={switchTenantOpen} onCancel={() => {
              setSwitchTenantOpen(false)
            }} onSuccess={(title: string) => {
              defaultSettings.title = title || '商城';
            }} />

          </div>
        )}
        menuItemRender={(menuItemProps, defaultDom) => {
          if (menuItemProps.isUrl || !menuItemProps.path) {
            return defaultDom;
          }
          return <Link to={menuItemProps.path}>{defaultDom}</Link>;
        }}
        menuDataRender={() => {
          return fetchMenu(menu, authInfo?.menus || []);
        }}
        headerContentRender={headerContentRender}
        rightContentRender={() => <RightContent />}
        style={{
          maxHeight: '100vh',
        }}
        disableContentMargin={true}
        // splitMenus={true}
        {...props}
        {...defaultSettings}
      >
        {props.children}
      </ProLayout>
    </>);
};

export default BasicLayout;
