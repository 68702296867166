import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import { metaData, MetaDataState } from './metadata.reducer';
import { authentication, AuthenticationState } from "../common/auth/authentication";
import { dashboard, DashboardState } from 'pages/home/dashboard.reducer';
import { settingData, SettingState } from './i18n.reducer';
import { unit, UnitState } from 'pages/dealer/unit/unit.reducer';
import { DealerCustomerState, dealerCustomer } from 'pages/dealer/customer/customer.reducer';
import { DealerLevelState, dealerLevel } from 'pages/dealer/level/level.reducer';
import { DealerInfoState, dealerInfo } from 'pages/dealer/dealerInfo/dealerInfo.reducer';
import { BonusListState, bonusList } from 'pages/bonus/list/bonus.reducer';
import { CustomerState, customer } from 'pages/customerCore/customer/customer.reducer';
import { EquityState, equity } from 'pages/customerCore/equity/equity.reducer';
import { GroupState, group } from 'pages/customerCore/group/group.reducer';
import { LevelState, level } from 'pages/customerCore/level/level.reducer';
import { TagState, tag } from 'pages/customerCore/tag/tag.reducer';
import { TagTaskState, tagTask } from 'pages/customerCore/tag-task/tagTask.reducer';
import { ActiveUserState, activeUser } from 'pages/customerCore/user/user.reducer';
import { WalletState, wallet } from 'pages/customerCore/wallet/wallet.reducer';
import { brand, BrandState } from 'pages/pim/brand/brand.reducer';
import { category, CategoryState } from 'pages/pim/category/category.reducer';
import { classification, ClassificationState } from 'pages/pim/classification/classification.reducer';
import { delivery, DeliveryState } from 'pages/pim/delivery/delivery.reducer';
import { onlineClassification, OnlineClassificationState } from 'pages/pim/online-classification/online.reducer';
import { pricingRules, PricingRulesState } from 'pages/pim/pricingRules/pricingRules.reducer';
import { rewardconfig, RewardconfigState } from 'pages/rewardMng/rewardconfig/rewardconfig.reducer';
import { payorder, PayorderState } from 'pages/paymng/payorder/payorder.reducer';
import { modifyDealer, ModifyDealerState } from 'pages/rewardMng/rewardconfig/modifyDealer.reducer';
import { product, ProductState } from 'pages/pim/product/product.reducer';
import { region, RegionState } from 'pages/pim/region/region.reducer';
import { splitOrderType, SplitOrderTypeState } from 'pages/pim/split-order-type/split-order-type.reducer';
import { stock, StockState } from 'pages/pim/stock/stock.reducer';
import { wholesaleProduct, WholesaleProductState } from 'pages/pim/wholesale/wholesale.reducer';
import { achievement, AchievementState } from 'pages/pim/achievement/achievement.reducer';
import { point, PointState } from 'pages/pim/point/point.reducer';
import { audit, AuditState } from 'pages/system/audit/audit.reducer';
import { afterSales, AfterSalesState } from 'pages/procure/after-sales/after-sales.reducer';
import { orderCancel, OrderCancelState } from 'pages/procure/order-cancel/order-cancel.reducer';
import { orderAfterSales, OrderAfterSalesState } from 'pages/procure/order-after-sales/order-after-sales.reducer';
import { order, OrderState } from 'pages/procure/purchase-order/order.reducer';
import { orderReturnPackage, OrderReturnPackageState } from 'pages/procure/return-package/return-package.reducer';
import { wholesaleOrder, wholesaleOrderState } from 'pages/procure/wholesale-orders/order.reducer';
import { bankStatement, BankStatementState } from 'pages/financial/bankStatement/bankStatement.reducer';
import { flowTemplate, FlowTemplateState } from 'pages/financial/flowTemplate/flowTemplate.reducer';
import { fund, FundState } from 'pages/financial/fund/fund.reducer';
import { store, StoreState } from 'pages/store/store/store.reducer';
import { address, AddressState } from 'pages/store/address/address.reducer';
import { marginRules, MarginRulesState } from 'pages/store/marginRules/marginRules.reducer';
import { excellentStore, ExcellentStoreState } from 'pages/dealerStore/excellentStore/excellentStore.reducer';
import { lifePavilion, LifePavilionState } from 'pages/dealerStore/lifePavilion/lifePavilion.reducer';
import { physicalStores, PhysicalStoresState } from 'pages/dealerStore/physicalStores/physicalStores.reducer';
import { trainingList, TrainingListState } from 'pages/dealerStore/trainingList/trainingList.reducer';
import { contractTemplate, ContractTemplateState } from 'pages/contractAdmin/contractTemplate/contractTemplate.reducer';
import { RelationshipTreeState, relationshipTree } from 'pages/dealer/relationshipTree/relationshipTree.reducer';
import { RelationTreeState, relationTree } from 'pages/dealer/relationAdmin/relationTree.reducer';
import { TrainListState, trainList } from 'pages/train/trainList/trainList.reducer';
import { SalonReimbursmtState, salonReimbursmt } from 'pages/train/salonReimbursmt/salonReimbursmt.reducer';
import { SalonApplicationState, salonApplication } from 'pages/train/salonApplication/salonApplication.reducer';
import { VideoCheckInState, videoCheckIn } from 'pages/train/videoCheckIn/videoCheckIn.reducer';
import { SendOutGoodsState, sendOutGoods } from 'pages/scanCodeOrder/sendOutGoods/sendOutGoods.reducer';
import { RetailState, retail } from 'pages/scanCodeOrder/retail/retail.reducer';
import { rewardList, RewardListState } from 'pages/bonus/reward/reward.reducer';
import { ContractState, contract } from 'pages/contract/list/list.reducer';
import { SummaryListState, summaryList } from 'pages/bonus/summary/summary.reducer';
import { EvaluationState, evaluation } from 'pages/dealer/evaluation/evaluation.reducer';
import { tenant, TenantState } from 'pages/system/tenant/tenant.reducer';
import { VersionState, version } from 'pages/system/app-version/version.reducer';
import { unusualMessage, UnusualMessageState } from 'pages/system/message/message.reducer';
import { payeeEnterpriseState, payeeEnterprise } from 'pages/system/payee-enterprise/payee-enterprise.reducer';
import { RouteState, route } from 'pages/system/route/route.reducer';
import { PayState, pay } from 'pages/system/pay/pay.reducer';
import { RateState, rate } from 'pages/system/rate/rate.reducer';
import { express, ExpressState } from 'pages/system/express-company/express.reducer';
import { permissionState, permission } from 'pages/user/permission/permission.reducer';
import { RoleState, role } from 'pages/user/role/role.reducer';
import { UserState, user } from 'pages/user/user/user.reducer';
import { ClientState, client } from 'pages/user/client/client.reducer';
import { powerModuleState, powerModule } from 'pages/user/power-module/power-module.reducer';
import { ResourcesState, resources } from 'pages/user/resources/resources.reducer';
import { DecorateState, decorate } from 'pages/decorate/platform/platform.reducer';
import { decorateEdit } from 'pages/decorate/platform/platform-edit.reducer';
import { ExamDecorateState, examDecorate } from 'pages/examMng/examDecorate/platform.reducer';
import { examDecorateEdit } from 'pages/examMng/examDecorate/examplatform-edit.reducer';
import { ContractSignState, contractSign } from 'pages/contract/sign/sign.reducer';
import { SecondReviewState, secondReview } from 'pages/dealer/secondReview/secondReview.reducer';
import { SecondSettledState, secondSettled } from 'pages/dealer/secondSettled/secondSettled.reducer';
import { DealerBrandLevelState, dealerBrandLevel } from 'pages/dealer/brandLevel/brandLevel.reducer';
import { AssortState, assort } from 'pages/dealer/assort/assort.reducer';
import { ArticleState, article } from 'pages/dealer/article/article.reducer';
import { spec, SpecState } from 'pages/pim/spec/spec.reducer';
import { logisticscode, LogisticscodeState } from 'pages/scanCodeOrder/logisticscode/logisticscode.reducer';
import { RefundState, refund } from 'pages/procure/refund/refund.reducer';
import { ExchangeGoodsState, exchangeGoods } from 'pages/scanCodeOrder/exchangeGoods/exchangeGoods.reducer';
import { ExchangeState, exchange } from 'pages/scanCodeOrder/exchange/exchange.reducer';
import { ComplaintState, complaint } from 'pages/dealer/complaint/complaint.reducer';
import { AdvertisementState, advertisement } from 'pages/market/advertisement/advertisement.reducer';
import { ReturnGoodsState, returnGoods } from 'pages/scanCodeOrder/returnGoods/returnGoods.reducer';
import { DirectAscentState, directAscent } from 'pages/dealer/directAscent/directAscent.reducer';
import { TopicState, topic } from 'pages/video/topic/topic.reducer';
import { PublisherState, publisher } from 'pages/video/publisher/publisher.reducer';
import { PostState, post } from 'pages/video/post/post.reducer';
import { VideoCategoryState, videoCategory } from 'pages/video/category/category.reducer';
import { AuthFlowState, authFlow } from 'pages/contract/authFlow/authFlow.reducer';
import { SealAuthState, sealAuth } from 'pages/contract/sealAuth/sealAuth.reducer';
import { CouponState, coupon } from 'pages/market/coupon/coupon.reducer';
import { CourseState, course } from 'pages/examMng/course/course.reducer';
import { ExamTopicState, examtopic } from 'pages/examMng/topic/topic.reducer';
import { MaterialState, material } from 'pages/examMng/materialCenter/material.reducer';
import { MenuListState, menuList } from 'pages/system/menuList/menuList.reducer';
import { AssessmentState, assessment } from 'pages/bonus/assessmentDetails/assessment.reducer';
import { ActivityState, activity } from 'pages/promotion/activity/activity.reducer';
import { WechatbindState, wechatbind } from 'pages/customerCore/wechatbind/wechatbind.reducer';
import { FullGiftState, fullGift } from 'pages/promotion/fullGift/fullGift.reducer';
import { FeedbackState, feedback } from 'pages/user/feedback/feedback.reducer';
import { MaterialDownState, materialDown } from 'pages/pim/materialDown/materialDown.reducer';
import { BuyItNowState, buyItNow } from 'pages/pim/buyItNow/buyItNow.reducer';

export interface RootState {
  readonly authentication: AuthenticationState;
  readonly metaData: MetaDataState;
  readonly loadingBar: any;
  readonly dashboard: DashboardState;
  readonly settingData: SettingState;
  //营销活动
  readonly advertisement: AdvertisementState;
  readonly coupon: CouponState;

  //促销中心
  readonly activity: ActivityState;

  //经销商列表
  readonly unit: UnitState;
  readonly dealerCustomer: DealerCustomerState;
  readonly dealerLevel: DealerLevelState;
  readonly dealerBrandLevel: DealerBrandLevelState;
  readonly dealerInfo: DealerInfoState;
  readonly relationshipTree: RelationshipTreeState;
  readonly relationTree: RelationTreeState;
  readonly evaluation: EvaluationState;
  readonly directAscent: DirectAscentState;
  readonly secondSettled: SecondSettledState;
  readonly assort: AssortState;
  readonly article: ArticleState;
  readonly complaint: ComplaintState;
  readonly examtopic: ExamTopicState;

  //促销中心
  readonly secondReview: SecondReviewState;
  readonly fullGift: FullGiftState;


  //素材
  readonly topic: TopicState;
  readonly publisher: PublisherState;
  readonly post: PostState;
  readonly videoCategory: VideoCategoryState;

  //奖金管理
  readonly bonusList: BonusListState;
  readonly rewardList: RewardListState;
  readonly summaryList: SummaryListState;

  //客户中心
  readonly customer: CustomerState;
  readonly equity: EquityState;
  readonly group: GroupState;
  readonly level: LevelState;
  readonly tag: TagState;
  readonly tagTask: TagTaskState;
  readonly activeUser: ActiveUserState;
  readonly wallet: WalletState;
  readonly wechatbind: WechatbindState;
  //商品
  readonly brand: BrandState;
  readonly buyItNow: BuyItNowState;
  readonly category: CategoryState;
  readonly classification: ClassificationState;
  readonly delivery: DeliveryState;
  readonly onlineClassification: OnlineClassificationState;
  readonly pricingRules: PricingRulesState;
  readonly rewardconfig: RewardconfigState;
  readonly assessment: AssessmentState;
  readonly payorder: PayorderState;
  readonly modifyDealer: ModifyDealerState;
  readonly product: ProductState;
  readonly region: RegionState;
  readonly splitOrderType: SplitOrderTypeState;
  readonly stock: StockState;
  readonly wholesaleProduct: WholesaleProductState;
  readonly achievement: AchievementState;
  readonly point: PointState;
  readonly spec: SpecState;
  readonly exchangeGoods: ExchangeGoodsState;
  readonly logisticscode: LogisticscodeState;
  readonly materialDown: MaterialDownState;

  //订单管理
  readonly afterSales: AfterSalesState;
  readonly orderAfterSales: OrderAfterSalesState;
  readonly orderCancel: OrderCancelState;
  readonly order: OrderState;
  readonly orderReturnPackage: OrderReturnPackageState;
  readonly wholesaleOrder: wholesaleOrderState;
  readonly refund: RefundState;
  readonly exchange: ExchangeState;

  //财务管理
  readonly bankStatement: BankStatementState;
  readonly flowTemplate: FlowTemplateState;
  readonly fund: FundState;

  //店铺管理
  readonly store: StoreState;
  readonly address: AddressState;
  readonly marginRules: MarginRulesState;

  //门店管理
  readonly excellentStore: ExcellentStoreState;
  readonly lifePavilion: LifePavilionState;
  readonly physicalStores: PhysicalStoresState;
  readonly trainingList: TrainingListState;

  //合同管理
  readonly contractTemplate: ContractTemplateState;

  //培训会
  readonly trainList: TrainListState;
  readonly salonApplication: SalonApplicationState;
  readonly salonReimbursmt: SalonReimbursmtState;
  readonly videoCheckIn: VideoCheckInState;
  //扫码订单
  readonly sendOutGoods: SendOutGoodsState;
  readonly retail: RetailState;
  readonly returnGoods: ReturnGoodsState;

  //电子合同
  readonly contract: ContractState;
  readonly contractSign: ContractSignState;
  readonly authFlow: AuthFlowState;
  readonly sealAuth: SealAuthState;

  //系统管理
  readonly tenant: TenantState;
  readonly version: VersionState;
  readonly audit: AuditState;
  readonly unusualMessage: UnusualMessageState;
  readonly payeeEnterprise: payeeEnterpriseState;
  readonly route: RouteState;
  readonly pay: PayState;
  readonly rate: RateState;
  readonly express: ExpressState;
  readonly menuList: MenuListState;

  //用户管理
  readonly permission: permissionState;
  readonly role: RoleState;
  readonly user: UserState;
  readonly client: ClientState;
  readonly powerModule: powerModuleState;
  readonly resources: ResourcesState;
  readonly feedback: FeedbackState;

  //页面装修
  readonly decorate: DecorateState;
  readonly decorateEdit: any;
  readonly examDecorateEdit: any;
  readonly examDecorate: ExamDecorateState;


  // 考试管理
  readonly course: CourseState;
  readonly material: MaterialState;


}

const rootReducer = combineReducers<RootState>({
  buyItNow,
  materialDown,
  logisticscode,
  wechatbind,
  feedback,
  examDecorate,
  fullGift,
  examtopic,
  assessment,
  material,
  menuList,
  activity,
  course,
  authFlow,
  coupon,
  sealAuth,
  videoCheckIn,
  salonApplication,
  publisher,
  post,
  videoCategory,
  topic,
  salonReimbursmt,
  payorder,
  contractTemplate,
  modifyDealer,
  spec,
  refund,
  returnGoods,
  directAscent,
  complaint,
  secondReview,
  exchange,
  exchangeGoods,
  article,
  assort,
  dealerBrandLevel,
  secondSettled,
  contractSign,
  decorate,
  decorateEdit,
  examDecorateEdit,
  resources,
  permission,
  powerModule,
  express,
  user,
  client,
  role,
  version,
  audit,
  unusualMessage,
  payeeEnterprise,
  route,
  pay,
  rate,
  tenant,
  evaluation,
  summaryList,
  contract,
  rewardList,
  sendOutGoods,
  retail,
  trainList,
  excellentStore,
  lifePavilion,
  physicalStores,
  relationshipTree,
  relationTree,
  trainingList,
  store,
  address,
  marginRules,
  bankStatement,
  flowTemplate,
  fund,
  afterSales,
  orderAfterSales,
  orderCancel,
  order,
  orderReturnPackage,
  wholesaleOrder,
  authentication,
  metaData,
  loadingBar,
  dashboard,
  settingData,
  advertisement,
  unit,
  dealerCustomer,
  dealerLevel,
  dealerInfo,
  bonusList,
  customer,
  equity,
  group,
  level,
  tag,
  tagTask,
  wallet,
  activeUser,
  brand,
  category,
  classification,
  delivery,
  onlineClassification,
  pricingRules,
  rewardconfig,
  product,
  region,
  splitOrderType,
  stock,
  wholesaleProduct,
  achievement,
  point,
});
export default rootReducer;
