import { FC, useEffect, useRef, useState } from 'react'
import { Layout, Button, Space, Typography, Timeline, Tag, Tabs, Radio, Drawer, message } from 'antd';
import { Order, LinesItem, PackageList } from 'model/procure/order.model';
import { RouteComponentProps } from '@reach/router';
import { getEntity, updateRemark, updateConsignee, updateShip, queryLogistic, updateLogistics, updatePostage, updateShipMiddleground, fetchPackage, confirmOrderReceive, pushCenter, abandonedCoupon } from './order.reducer';
import { createBatchEntity } from '../after-sales/after-sales.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from 'layouts/page-wraper/details-page';
import { RootState } from 'reducers';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';
import { useEnumValue } from 'common/enum/use-enum-value';
import { EditOutlined, EnvironmentOutlined, EyeOutlined, ReloadOutlined } from '@ant-design/icons';
import OrderDetailsUpdateMsg from './../components/order-details-update-msg';
import OrderDetailsUpdatePackage from '../components/order-details-update-package';
import Authorized from 'common/auth/authorized';
import { internationalList } from 'components/IntelFormText';
import Title from 'antd/lib/typography/Title';
import AuditModalForm from 'pages/system/audit/audit-modal-form';
import { useParamGeneral } from 'components/useParamGenera';
import { associateOrders } from '../purchase-order/order.reducer'
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import { ModalForm } from '@ant-design/pro-form';
import ExportableModal from './canvasImage';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const { Paragraph } = Typography;
const { Content } = Layout;

interface packageRecord {
  entity?: any;
  isVisible?: boolean;
}

export interface OrderDetailsProps extends DetailsStateAndDispatchProps<Order>,
  RouteComponentProps, StateProps, DispatchProps {
  id?: string;
  type?: string;
  listType?: 'order' | 'wholesale';
  detailText?: string;
  detailRow?: any;
}

const OrderDetails: FC<OrderDetailsProps> = (props) => {
  const { detailRow, id, entity, listType, detailText } = props;
  const { getEnumValueDesc } = useEnumValue();
  const [sellerMsg, setSellerMsg] = useState<any>('');
  const [trajectoryList, setTrajectoryList] = useState<any>([]);
  const [logisticsShow, SetLogisticsShow] = useState<any>(false);
  const [packageRecord, setPackageRecord] = useState<packageRecord>({});
  const { currency } = useParamGeneral();
  const [mainOrder, setMainOrder] = useState<any>([]); // 关联订单  
  const [associateOrder, setAssociateOrderList] = useState<any>([]); // 关联订单  
  const [orderDetail, setOrderDetail] = useState<any>({}); // 订单详情
  const [associateActiveIndex, setAssociateActiveIndex] = useState<number>(0);

  const ref = useRef<ActionType>();


  // 物流Columns
  const expressColumns: ProColumns<PackageList>[] = [{
    title: '运单号',
    dataIndex: 'actualExpressNo',
    width: 150,
  }, {
    title: '快递公司',
    dataIndex: 'actualExpressName',
    width: 150,
  }, {
    title: '签收时间',
    dataIndex: 'signForTime',
    width: 100,
  },
  {
    title: '包裹中商品',
    width: 200,
    render: (text, row) => {
      return row?.tstPackageInfo?.dts && row?.tstPackageInfo?.dts.map((e: any, index: Number) => {
        return <p className='package-box'>
          <p className='name'>
            {e.skuName}
            <p><span>{e.skuCode}</span></p>
          </p>
          <span className='qty'>* {e.qty}</span>
        </p>
      })
    }
  },
  {
    title: '物流轨迹',
    width: 100,
    render: (text, record: any) => {
      return <Button size="small" type='link' style={{ padding: '0' }} onClick={() => loogwl(record)} icon={<EyeOutlined />}>查看</Button>
    }
  }];


  const expressColumnsAction: ProColumns<any>[] = [{
    title: '操作',
    render: (text, record) => {
      return <Button type="link" size="small"
        onClick={() => { setPackageRecord({ entity: record, isVisible: true }) }}
        icon={<EditOutlined />}
      ></Button>
    }
  }]

  // 商品信息columns
  const columns: ProColumns<LinesItem, 'purchaseOrderLineStatus'>[] = [{
    title: '商品名称',
    dataIndex: '',
    width: 200,
    fixed: 'left',
    render: (text: any, row: any) => {
      return <div className='query-order-name'>
        <div>
          <p className='productName'>{internationalList(row.productName || '')}</p>
          <p>
            规格编码：{row.skuCode}
          </p>
        </div>
        <img alt='商品图片' style={{ width: '60px', height: 'auto', marginRight: '10px' }} src={internationalList(row?.picture || '')} />
      </div>
    }
  }, {
    title: '颜色/尺码',
    dataIndex: 'sonItem',
    width: 200,
    render: (text, row) => {
      return row?.specName?.map((v: any) => {
        return <><p>{v.k}: {v.v}</p></>
      })
    }
  }, {
    title: '商品单价',
    dataIndex: 'memberPrice',
    render: (text) => {
      return currency?.symbol + ' ' + text
    }
  },
  {
    title: '数量',
    dataIndex: 'quantity',
  }, {
    title: '发货数量',
    dataIndex: 'shippedQuantity',
  }, {
    title: '取消数量',
    dataIndex: 'cancelledQuantity',
  }, {
    title: '行小计',
    dataIndex: 'subtotal',
    render: (text) => {
      return currency?.symbol + ' ' + text
    }
  }, {
    title: '发货状态',
    dataIndex: 'status',
    valueType: 'purchaseOrderLineStatus',
  }];


  useEffect(() => {
    id && props.getEntity(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, detailText]);

  // 获取表单初始值
  useEffect(() => {
    setOrderDetail(entity)
    setMainOrder(entity)
    setSellerMsg(entity.sellerMsg);
    getAssociate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  // 获取关联订单
  const getAssociate = () => {
    if (detailRow.hasAssociate) {
      associateOrders(detailRow.id).then(e => {
        setAssociateOrderList(e)
      })
    }
  }

  // 查看物流
  const loogwl = (record: any) => {
    queryLogistic({
      expressNo: record?.expressNo,
      expressCode: record?.expressCode,
      mobile: entity?.address?.mobile,

    }).then((trajectory) => {
      if (trajectory?.traces?.length > 0) {
        setTrajectoryList(trajectory.traces || []);
        SetLogisticsShow(true)
      } else {
        message.error('暂无物流轨迹信息')
      }
    })
  }


  // 详情内容
  const OrderContent = () => {
    const orderState: any = orderDetail?.orderState || 0;
    return (
      <div className='order-details'>
        <div className='order-div-states'>
          <Space direction='vertical' style={{ width: '25%' }}>
            <h3>
              {orderDetail.orderState === 1 ? '买家已拍下，等待买家付款' :
                orderDetail.orderState === 4 ? '已接单，等待发货' :
                  [5, 6, 20, 97].indexOf(orderState) > -1 ? '商家发货' :
                    getEnumValueDesc('orderStatus', orderDetail.status || '')}
            </h3>
            <p>
              {orderDetail.orderState === 4 ? '买家已付款，请尽快发货，否则买家有权申请退货' :
                orderDetail.orderState === 99 ? internationalList(props?.entity?.closeReasonDesc || '') : null}
            </p>
          </Space>
        </div>
        <Space align='start' direction='horizontal' className='order-div-content'>
          <Content className='order-info'>
            <h3>订单信息</h3>
            <span>订单ID：{orderDetail.id}</span>
            <span>订单编号：{orderDetail.orderNo}</span>
            <span>下单时间：{orderDetail.bookTime}</span>
            <span>付款时间：{orderDetail.payTime}</span>
            <span>订单类型: {getEnumValueDesc('purchaseOrderType', orderDetail.purchaseOrderType || '') || '-'}</span>
            <span>订单状态：{getEnumValueDesc('purchaseOrderStatusLabel', orderDetail.statusLabel || '')}{getEnumValueDesc('purchaseOrderStatus', orderDetail.orderState || '') || '-'}</span>

            {entity?.type === 'Manual' && <><span>订单备注：{orderDetail.sellerMsg}</span>
              <span>商家备注：{orderDetail.adminMsg}</span></>}
            <p className='sellerMsg-edit'>
              <span>订单备注:</span>
              <Authorized authority='OrderRemark'>
                <Paragraph
                  editable={listType === 'order' ? {
                    onChange: (e) => (e && sellerMsg !== e) && props.updateRemark(String(entity.id), e)
                  } : false}>{sellerMsg}
                </Paragraph>
              </Authorized>
            </p>
          </Content>

          <Content className='buyer-seller-wrapper'>
            <h3>买家信息</h3>
            <span>买家Id: {orderDetail.buyer?.buyerUserId}</span>
            <span>买家授权码: {orderDetail.buyer?.buyerCode}</span>
          </Content>
          <Content className='buyer-seller-wrapper'>
            <h3>卖家信息</h3>
            <span>是否自营: {orderDetail.seller?.selfSeller ? '是' : '否'}</span>
          </Content>


          {[1, 4].indexOf(orderDetail.expressType || 1) > -1 && <Content className='buyer-seller-wrapper'>
            <h3>收货人信息
              {
                ([1, 4, 6, 97, 20].indexOf(orderState) > -1) &&
                // {/* 修改发货信息弹框 */}
                <OrderDetailsUpdateMsg entity={entity} updateConsignee={props.updateConsignee} />
              }
            </h3>
            <p>收货人：{orderDetail.address?.consigneeName}</p>
            <p>联系电话：{orderDetail.address?.mobile}</p>
            <p>省市区：{orderDetail.address?.provinceName + ' '}
              {orderDetail.address?.cityName + ' '}
              {orderDetail.address?.districtName + ' '}</p>
            <p>收货地址：{orderDetail.address?.address}</p>
            <p>客户ID：{orderDetail.buyer?.buyerCustomerId}</p>
            <p>买家留言：{orderDetail.buyerMsg}</p>
          </Content>}
          {orderDetail.expressType === 3 && <Content className='order-info-user'>
            <h3>收货人信息
              <span style={{ marginLeft: '10px' }}><Tag color="#108ee9" >商超单</Tag></span>
            </h3>
            <p>收货人：{orderDetail.address?.consigneeName}</p>
            <p>联系电话：{orderDetail.address?.mobile}</p>
            <p>收货地址：{orderDetail.address?.storeAddress}{orderDetail.address?.storeName}</p>
            <p>客户ID：{orderDetail?.buyerId}</p>
            <p>买家留言：{orderDetail?.buyerMsg}</p>
          </Content>}
        </Space>
      </div>
    )
  };


  // 发货/取消订单/退款
  const operation = [
    <>
      <AuditModalForm rowId={entity.id + ''} className='Order' />
    </>,
    <ModalForm
      title="提示"
      width={300}
      trigger={<Button>订单推中台</Button>}
      onFinish={async () => {
        return new Promise<boolean>((resolve, reject) => {
          pushCenter({ id: entity.id }).then(e => {
            message.success('推送成功')
            resolve(true);
          }).catch(e => {
            message.error('推送错误');
            resolve(false);
          })
        });
      }}
    >确认要将订单：{entity.id} 推中台？</ModalForm>
  ];

  const OrderContentTemplate = () => {
    return (<>
      <OrderContent />
      <Title level={5}>商品信息</Title>
      <ProTable<LinesItem, Record<string, any>, 'purchaseOrderLineStatus'>
        className="top-align-table"
        columns={columns}
        rowKey="id"
        dataSource={orderDetail?.lines}
        scroll={{ x: 800 }}
        pagination={false}
        options={false}
        search={false}
      />
      <Title style={{ marginTop: '30px' }} level={5}>物流信息<Button type="link" size="small" onClick={() => ref.current?.reload()}><ReloadOutlined />刷新</Button></Title>
      {
        entity?.id && <ProTable<any>
          actionRef={ref}
          className="express-table"
          columns={listType === 'order' ?
            expressColumns.concat(expressColumnsAction) : expressColumns}
          rowKey="express"
          params={{ id: entity.id }}
          pagination={false}
          request={async (params) => {
            let res = await fetchPackage({
              sort: 'lastModifiedDate,desc',
              id: entity.id,
            });
            return Promise.resolve({
              data: res?.data || [],
              success: true,
            });
          }}
          options={false}
          search={false}
        />
      }

      {/* 物流信息修改 */}
      <OrderDetailsUpdatePackage
        orderEntity={entity}
        packageEntity={packageRecord?.entity}
        visible={packageRecord?.isVisible || false}
        getEntity={() => ref.current?.reload()}
        onCancel={() => { setPackageRecord({ ...setPackageRecord, isVisible: false }) }}
      />

      {/* 物流轨迹 */}
      <Drawer
        visible={logisticsShow}
        title='物流轨迹'
        onClose={() => SetLogisticsShow(false)}
        width={1000}
      >
        <div className=''>
          {trajectoryList.length > 0 ?
            <Timeline reverse>
              {trajectoryList.map((e: any, index: Number) => {
                return <Timeline.Item key={e.id} dot={index === trajectoryList.length - 1 && <EnvironmentOutlined />}>
                  <div className='trajectory-box-li'>
                    <p>{index === trajectoryList.length - 1 && <Tag color="#f50">最新</Tag>}{e.status}</p>
                    <p>{e.time}</p>
                  </div>
                </Timeline.Item>
              })}
            </Timeline>
            : <>暂无物流轨迹信息</>}
        </div>
      </Drawer>
    </>)
  }

  return (
    <ExportableModal onExport={async (imgData: any, type: '1' | '2', element: any) => {
      if (type === '2') {
        const link = document.createElement('a');
        link.download = '订单详情.png';
        link.href = imgData;
        link.click();
      } else {
        // 生成高清Canvas
        const canvas = await html2canvas(element, {
          scale: 2, // 提升分辨率
          useCORS: true,
          logging: false
        });

        // 初始化PDF
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const adjustedPageHeight = pageHeight - 20; // 40mm = 4cm（2cm上 + 2cm下）

        // 计算缩放比例（仅约束宽度）
        const imgProps = pdf.getImageProperties(canvas.toDataURL('image/jpeg', 0.9));
        const scale = pageWidth / imgProps.width; // 固定宽度适配
        const imgHeight = imgProps.height * scale; // 图片总高度（毫米）

        // 分页切割参数
        let currentPosition = 0; // 当前切割位置
        let pageNumber = 1;       // 当前页码

        while (currentPosition < imgHeight) {
          // 添加新页（首页不需要）
          if (pageNumber > 1) pdf.addPage();

          // 计算本页能容纳的高度
          const remainingHeight = imgHeight - currentPosition;
          const viewportHeight = Math.min(remainingHeight, adjustedPageHeight);

          // 核心：截取图片指定区域
          const cropY = currentPosition / scale; // 原始像素位置
          const cropHeight = viewportHeight / scale;

          // 创建临时Canvas进行局部截取
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = imgProps.width;
          tempCanvas.height = cropHeight;
          const ctx: any = tempCanvas.getContext('2d');
          ctx.drawImage(
            canvas,
            0, cropY,          // 源图像起始位置
            imgProps.width, cropHeight, // 截取区域尺寸
            0, 0,               // 画布起始位置
            imgProps.width, cropHeight
          );

          // 将局部Canvas转为DataURL
          const partialData = tempCanvas.toDataURL('image/jpeg', 0.9);

          // 判断是否是最后一页（剩余高度不足一页）
          const isLastPage = remainingHeight <= pageHeight;

          // 添加本页内容（自动垂直居中）
          pdf.addImage({
            imageData: partialData,
            format: 'JPEG',
            x: 0,
            // y: isLastPage ? 0 : (pageHeight - viewportHeight) / 2, // 条件判断
            y:10,
            width: pageWidth,
            height: viewportHeight
          });

          // 更新切割位置
          currentPosition += viewportHeight;
          pageNumber++;
        }

        pdf.save('分页导出.pdf');
      }

    }}>
      <DetailsPage<Order>
        title={<Space size='middle'>订单详情</Space>}
        className='order-detail'
        operation={listType === 'order' ? [operation] : []}
        hideEdit={true}
        {...props}
      >
        {
          associateOrder.length > 0 ?
            <Tabs onChange={(e) => {
              setOrderDetail(e === '1' ? mainOrder : associateOrder[0])
            }}>
              <TabPane tab='主订单' key='1'>
                <OrderContentTemplate />
              </TabPane>
              <TabPane tab='关联订单' key='2'>
                <div className='related-orders-wrapper'>
                  <Radio.Group value={associateActiveIndex} onChange={(e) => {
                    setAssociateActiveIndex(e.target.value)
                    setOrderDetail(associateOrder[e.target.value])
                  }} style={{ marginBottom: 16 }}>
                    {associateOrder.map((item: any, index: number) => {
                      return <Radio.Button value={index}>订单编号【{item.orderNo}】</Radio.Button>
                    })}
                  </Radio.Group>
                </div>
                <OrderContentTemplate />
              </TabPane>
            </Tabs> : <OrderContentTemplate />
        }
      </DetailsPage>
    </ExportableModal>
  );
};

const mapStateToProps = ({ order }: RootState) => ({
  entity: order.entity,
  updateSuccess: order.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateShip,
  updateRemark,
  updateConsignee,
  createBatchEntity,
  updateLogistics,
  updatePostage,
  abandonedCoupon,
  updateShipMiddleground,
  confirmOrderReceive
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
