import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import BuyItNowDetails from './buyItNow-details';
import BuyItNowEdit from './buyItNow-edit';
import BuyItNowList from './buyItNow-list';

const BuyItNow: React.FC<RouteComponentProps> = () => (
  <Router>
    <BuyItNowList path="/" />
    <BuyItNowDetails path=":id" />
    <BuyItNowEdit path=":id/edit" />
    <BuyItNowEdit path="new" />
  </Router>
);
export default BuyItNow;
