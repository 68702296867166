import { Button } from 'antd';
import html2canvas from 'html2canvas';
import { useRef } from 'react';

const ExportableModal = (props: any) => {
    const { children, onExport } = props
    const captureRef = useRef(null);

    const handleExport = async (type: string) => {
        if (!captureRef.current) return;

        try {
            const canvas = await html2canvas(captureRef.current, {
                useCORS: true, // 允许跨域图片
                scale: 2,      // 提高分辨率
                logging: true, // 调试时开启
            });
            const imgData = canvas.toDataURL('image/png');
            onExport(imgData, type, captureRef.current); // 回调处理图片数据
        } catch (error) {
            console.error('导出失败:', error);
        }
    };

    return (
        <div className="modal">
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px', boxSizing: 'border-box' }}>
                <Button style={{marginRight:'10px'}} type='primary' onClick={() => handleExport('1')}>导出为PDF</Button>
                <Button type='primary' onClick={() => handleExport('2')}>导出为图片</Button>
            </div>
            <div ref={captureRef} className="modal-content">
                {children}
            </div>
        </div>
    );
};

export default ExportableModal;