import { FC, ReactNode, useState } from "react";
import { Form, Input, Space, Image, Drawer, Tag, Button, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import BuyItNowDetails from "./buyItNow-details";
import { addFixedPriceProduct, deleteEntity, getEntities, getEntity } from "./buyItNow.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import BuyItNowEdit from "./buyItNow-edit";
import { BuyItNow } from "model/product/buyItNow.model";
import { internationalList } from "components/IntelFormText";
import defaultPim from '../../../assets/defaultPim.png';
import { useEnumValue } from "common/enum/use-enum-value";
import { ForceUpdate } from "model/system/version.model";
import { DrawerForm, ModalForm } from "@ant-design/pro-form";
import ProductListEditControl, { NumList } from "../product/product-list-edit-control";
import { searchProductList } from "../product/product.reducer";

export interface BuyItNowListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}


const BuyItNowList: FC<BuyItNowListProps> = (props) => {

  const [detailShow, SetDetailShow] = useState(false);
  const [detailText, SetDetailText] = useState('');
  const [detailId, SetDetailId] = useState('');
  const [numList, setNumList] = useState<NumList[]>([]);

  const columns: ProColumns<BuyItNow>[] = [{
    title: '商品信息',
    dataIndex: 'mainPicture',
    valueType: 'image',
    width: 300,
    hideInDescriptions: true,
    render: ((dom: any, record: any) => {
      return <Space className="product-list-picture product-list-picture-pim">
        <Image src={internationalList(record.mainPicture || '') || defaultPim} />
        <ul>
          <li>
            <span className="product-name" onClick={() => {
              SetDetailShow(true); SetDetailText(new Date() + '');
              SetDetailId(record.id);
            }}>
              {internationalList(record.productName || '')}
            </span>
          </li>
          <li>商品ID：<span>{record.productId}</span></li>
          <li>商品编码：<span>{record.productCode}</span></li>
        </ul>
      </Space>
    })
  }, {
    title: '售价',
    dataIndex: 'retailPrice'
  }, {
    title: '划线价',
    dataIndex: 'originPrice'
  },  {
    title: '起订量',
    dataIndex: 'minOrderQuantity'
  }, {
    title: '排序',
    dataIndex: 'sort'
  }, {
    title: '是否展示',
    dataIndex: 'isShow',
    valueEnum: ForceUpdate,
  }];

  const searchFields = [{
    label: '商品名称',
    name: 'productName.contains',
  },{
    label: '商品编码',
    name: 'productCode.equals',
  },];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const actions = [<DrawerForm
    title="批量新增商品"
    trigger={<Button type="primary" icon={<></>}>批量新增商品</Button>}
    drawerProps={{ destroyOnClose: true, }}
    width={'70vw'}
    onFinish={async (values) => {
      addFixedPriceProduct(numList).then(e => {
        props.getEntities();
        message.success('操作成功');
      }).catch(e => {
        message.error(e.response.data.title);
      })
      return true;
    }}
  >
    <Form.Item label="选择商品" name='lines'>
      <ProductListEditControl hideProductIds spuType='buyItNow' level={'spu'} selectPimType='buyItNow' numListChange={(e: any) => setNumList(e)} fetchFunc={searchProductList} />
    </Form.Item>
  </DrawerForm>];
  return (
    <ListViewPage<BuyItNow>
      columns={columns}
      hideCreateButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<BuyItNowDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      actions={actions}
      editPage={(params: any) => <BuyItNowEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
      <Drawer
        visible={detailShow}
        onClose={() => SetDetailShow(false)}
        width={1000}
      >
        <BuyItNowDetails detailText={detailText} id={detailId} columns={columns} />
      </Drawer>
    </ListViewPage>
  );
};

const mapStateToProps = ({ buyItNow }: RootState) => ({
  entity: buyItNow.entity,
  entities: buyItNow.entities,
  loading: buyItNow.loading,
  updateSuccess: buyItNow.updateSuccess,
  createSuccess: buyItNow.createSuccess,
  total: buyItNow.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BuyItNowList);