import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import Product from './product';
import Stock from './stock';
import Brand from './brand';
import './index.less'
import Category from './category';
import Classification from './classification';
import Region from './region';
import Online from './online-classification';
import Delivery from './delivery';
import Wholesale from './wholesale';
import Achievement from './achievement';
import Point from './point';
import SplitOrderType from './split-order-type';
import PricingRules from './pricingRules';
import Spec from './spec';
import MaterialDown from './materialDown';
import BuyItNow from './buyItNow';

const Pim: React.FC<RouteComponentProps> = () => (
    <Router>
        <Product path="product/*" />
        <Stock path="stock/*" />
        <PricingRules path="pricingRules/*" />
        <Achievement path="achievement/*" />
        <Brand path="brand/*" />
        <Point path="point/*" />
        <Category path="category/*" />
        <Classification path="classification/*" />
        <Online path="online-classification/*" />
        <Region path="region/*" />
        <Delivery path="delivery/*" />
        <Wholesale path="wholesale-product/*" />
        <SplitOrderType path="split-order-type/*" />
        <Spec path="spec/*" />
        <MaterialDown path="materialDown/*" />
        <BuyItNow path="buyItNow/*" />
    </Router>
);
export default Pim;
